<template>
  <v-dialog v-model="showDialog" :fullscreen="$vuetify.breakpoint.mobile" width="400">
    <v-sheet class="pa-6 pa-md-10" color="white">
      <div class="d-flex gap-10">
        <div>
          <h1 class="mb-5">Terminate the contract</h1>
        </div>
      </div>

      <div>
        <v-select
            v-model="reason"
            :items="reasons"
            item-text="reason_name"
            item-value="reason_value"
            label="Termination Reason"
            outlined
        ></v-select>
      </div>

      <div class="d-flex">
        <v-btn class="mt-5 mx-auto" @click="showDialog = false">Cancel</v-btn>
        <v-btn class="mt-5 mx-auto" color="primary" @click="terminateContract" :disabled="!reason">Confirm</v-btn>
      </div>
    </v-sheet>
  </v-dialog>
</template>


<script>
import {EventBus} from "../../../../../../bootstrap/EventBus";
import contracts from "../../../../../../api/modules/contracts";

export default {
  name: 'ContractTerminatePopup',
  data: function () {
    return {
      showDialog: false,
      contract: {},
      reason: null,
      reasons: [
        {
          reason_name: 'Client’s death',
          reason_value: 'Client’s death'
        },
        {
          reason_name: 'Other reason',
          reason_value: 'Other reason'
        }
      ]
    }
  },
  created() {
    EventBus.$on('terminate-contract-popup', (contract) => {
      this.contract = contract;
      this.showDialog = true;
    });
  },
  methods: {
    async terminateContract() {
      await contracts.terminateContract(this.contract.id, {reason: this.reason}).then(() => {
        this.showDialog = false
        EventBus.$emit('terminate-contract-update')
      })
    }
  }
}
</script>