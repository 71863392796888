<template>
  <v-dialog v-model="showDialog" :fullscreen="$vuetify.breakpoint.mobile" width="400">
    <v-sheet class="pa-6 pa-md-10" color="white">
      <div class="d-flex gap-10">
        <div>
          <h1 class="mb-5">Prolongation</h1>
        </div>
      </div>

      <div class="mb-5">
        <v-select
          :items="['Prolongue Automatically', 'None']"
          label="Prolongation"
          v-model="type"
          outlined
        >
        </v-select>
        <div class="text-caption red--text mt-n3" v-show="$v.type.$dirty && $v.type.$invalid && !$v.type.required">Field is required</div>
      </div>

      <div class="mb-5">
        <v-text-field type="number" outlined label="Months" v-model="prolongation" :disabled="disabledMonth"/>
        <div class="text-caption red--text mt-n3" v-show="$v.prolongation.$dirty && $v.prolongation.$invalid && !$v.prolongation.required">Field is required</div>
        <div class="text-caption red--text mt-n3" v-show="$v.prolongation.$dirty && $v.prolongation.$invalid && !$v.prolongation.minValue">Only positive numbers</div>
      </div>

      <div class="d-flex">
        <v-btn class="mt-5 mx-auto" @click="showDialog = false">Cancel</v-btn>
        <v-btn class="mt-5 mx-auto" color="primary" @click="submit" :disabled="contract.prolongation === Number(this.prolongation)">Submit</v-btn>
      </div>
    </v-sheet>
  </v-dialog>
</template>


<script>
import {EventBus} from "../../../../../../bootstrap/EventBus";
import { required, integer, minValue } from 'vuelidate/lib/validators'
import contracts from '@/api/modules/contracts';

export default {
  name: 'ContractProlongationPopup',
  data: function () {
    return {
      showDialog: false,
      contract: {},
      initialContract: {},
      type: null,
      prolongation : null,
      disabledMonth: false
    }
  },
  watch: {
    type(val) {
      if (val === 'None') {
        this.disabledMonth = true
        this.prolongation = 0
      } else {
        this.disabledMonth = false
      }
    },
    contract: {
      deep: true,
      handler(val) {
        if (val.prolongation === 0) {
          this.type = 'None'
        } else {
          this.type= 'Prolongue Automatically'
        }
        this.prolongation = val.prolongation
      }
    }
  },
  validations: {
    prolongation: {
      required,
      integer,
      minValue: minValue(0)
    },
    type: {
      required
    }
  },
  created() {
    EventBus.$on('prolongation-contract-popup', (contract) => {
      this.contract = Object.assign({}, contract);
      this.showDialog = true;
    });
  },
  methods: {
    async submit() {
      this.$v.$touch()
      if(this.$v.$invalid) return;
      await contracts.prolongateContract(this.contract.id, {prolongation: this.prolongation})
        .then(() => {
          EventBus.$emit("terminate-contract-update")
          this.showDialog = false;
        })
    }
  }
}
</script>