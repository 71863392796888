<template>
  <div>
    <v-sheet>
      <v-card class="pa-10 my-10" max-width="1600">
        <v-card-title>
          <h3>Contract Terms</h3>
        </v-card-title>
        <v-card-text>
          <div class="d-flex justify-space-between align-center">
            <div>
              <div class="font-weight-bold text-caption">Date Start</div>
              <div class="font-weight-bold text-h6">{{ contract.start_date | moment("DD MMM YY")}}</div>
            </div>
            <div>
              <div class="font-weight-bold text-caption">Date End</div>
              <div class="font-weight-bold text-h6">{{ contract.end_date | moment("DD MMM YY")}}</div>
            </div>
            <div>
              <div class="font-weight-bold text-caption">Prolongation</div>
              <div v-if="contract.prolongation > 0" class="font-weight-bold text-h6">Prolongue Automatically (Months: {{ contract.prolongation }})</div>
              <div v-else class="font-weight-bold text-h6">None</div>
            </div>
            <div>
              <div class="font-weight-bold text-caption">Status</div>
              <div class="font-weight-bold text-h6 text-capitalize">{{ contract.status }}</div>
            </div>
            <div>
              <v-btn color="primary" @click="prolongationContract">EDIT PROLONGATION</v-btn>
            </div>
            <div>
              <v-btn color="error" @click="terminateContract" :disabled="contract.status === 'inactive'">TERMINATE</v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-sheet>
    <contract-terminate-popup />
    <contract-prolongation-popup />
  </div>
</template>

<script>
import contracts from "../../../../../api/modules/contracts";
import {EventBus} from "../../../../../bootstrap/EventBus";
import ContractTerminatePopup from "./contract-terms/ContractTerminatePopup"
import ContractProlongationPopup from "./contract-terms/ContractProlongationPopup"

export default {
  name: 'ContractsViewTerms',
  components: {ContractProlongationPopup, ContractTerminatePopup},
  data: function () {
    return {
      contract: {},
    }
  },
  created: function () {
    this.fetchContract()
    EventBus.$on('terminate-contract-update', () => {
      this.fetchContract()
    })
  },
  methods: {
    async fetchContract() {
      await contracts.fetchContract(this.$route.params.id).then(res => {
        this.contract = res.data;
      })
    },
    terminateContract() {
      if (!this.contract.id) return;
      EventBus.$emit('terminate-contract-popup', this.contract);
    },
    prolongationContract() {
      if (!this.contract.id) return;
      EventBus.$emit('prolongation-contract-popup', this.contract);
    }
  }
}
</script>